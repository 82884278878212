var Cameraland = Cameraland || {};

Cameraland.ElementShift = class {
    constructor(options) {
        this.setMobile = this.setMobile.bind(this);
        this.setDesktop = this.setDesktop.bind(this);

        this.options = $j.extend({}, {
            elementSelector: '.element-selector',
            elementHolderDesktop: '.holder-desktop',
            elementHolderMobile: '.holder-mobile'
        }, options);

        enquire.register('screen and (max-width:' + bp.medium + 'px)', {
            match: this.setMobile,
            unmatch: this.setDesktop
        });
    }

    setMobile() {
        const elementSelector = $j(this.options.elementSelector);

        if(elementSelector.parent(this.options.elementHolderDesktop)) {
            const detachedElement = elementSelector.detach();
            detachedElement.appendTo(this.options.elementHolderMobile);
        }
    }

    setDesktop() {
        const elementSelector = $j(this.options.elementSelector);

        if(elementSelector.parent(this.options.elementHolderMobile)) {
            const detachedElement = elementSelector.detach();
            detachedElement.appendTo(this.options.elementHolderDesktop);
        }
    }

};